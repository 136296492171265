import React, {useState}    from 'react';
import arrowLeft            from '../../Media/arrowLeft.png';
import arrowRight           from '../../Media/arrowRight.png';
import Logo1                from '../../Media/ParticipantLogos/Akkim.png';
import Logo2                from '../../Media/ParticipantLogos/Allianz.png';
import Logo4                from '../../Media/ParticipantLogos/AstraZeneca.png';
import Logo5                from '../../Media/ParticipantLogos/BAT.png';
import Logo6                from '../../Media/ParticipantLogos/Bosch.png';
import Logo7                from '../../Media/ParticipantLogos/CCI.png';
import Logo8                from '../../Media/ParticipantLogos/Colgate.png';
import Logo9                from '../../Media/ParticipantLogos/ÇimSA.png';
import Logo10               from '../../Media/ParticipantLogos/EnerjiSA.png';
import Logo11               from '../../Media/ParticipantLogos/Eti.png';
import Logo12               from '../../Media/ParticipantLogos/JTI.png';
import Logo13               from '../../Media/ParticipantLogos/KEAS.png';
import Logo14               from '../../Media/ParticipantLogos/KordSA.png';
import Logo15               from '../../Media/ParticipantLogos/KPMG.png';
import Logo16               from '../../Media/ParticipantLogos/Metro.webp';
import Logo17               from '../../Media/ParticipantLogos/Oyak-Renault.png';
import Logo18               from '../../Media/ParticipantLogos/Penti.png';
import Logo19               from '../../Media/ParticipantLogos/PepsiCO.png';
import Logo20               from '../../Media/ParticipantLogos/PMSA.png';
import Logo21               from '../../Media/ParticipantLogos/QNB-Finansbank.png';
import Logo22               from '../../Media/ParticipantLogos/Sahibinden.png';
import Logo23               from '../../Media/ParticipantLogos/Sanofi.png';
import Logo24               from '../../Media/ParticipantLogos/SistemGlobal.png';
import Logo25               from '../../Media/ParticipantLogos/Şişecam.png';
import Logo26               from '../../Media/ParticipantLogos/Tat.png';
import Logo27               from '../../Media/ParticipantLogos/Tusas.png';
import Logo28               from '../../Media/ParticipantLogos/TürkTelekom.png';
import Logo29               from '../../Media/ParticipantLogos/Unilever.png';
import Logo30               from '../../Media/ParticipantLogos/Vodafone.png';
import Logo31               from '../../Media/ParticipantLogos/Yıldız-Holding.png';

import Logo32               from '../../Media/ParticipantLogos/Arcelik.webp';
import Logo33               from '../../Media/ParticipantLogos/Bayer.webp';
import Logo34               from '../../Media/ParticipantLogos/Dohler.webp';
import Logo35               from '../../Media/ParticipantLogos/Hayat.webp';
import Logo36               from '../../Media/ParticipantLogos/Metro.webp';
import Logo37               from '../../Media/ParticipantLogos/Mey-Diageo.webp';
import Logo38               from '../../Media/ParticipantLogos/Perfetti-Van-Melle.webp';
import Logo39               from '../../Media/ParticipantLogos/PwC.webp';
import Logo40               from '../../Media/ParticipantLogos/TEI.webp';

import "./Participants.css";

const Participants = () => {
    const [option, setOption] = useState(1);
    
    function scrollToNextPage() {
        const gallery = document.getElementsByClassName("participantCompanies")[0];
        const participantsScroller = gallery.getElementsByClassName("participantsScroller")[0];
        const gallery_item_size = participantsScroller.querySelector('img').clientWidth;
        participantsScroller.scrollBy(gallery_item_size, 0);
    }

    function scrollToPrevPage() {
        const gallery = document.getElementsByClassName("participantCompanies")[0];
        const participantsScroller = gallery.getElementsByClassName("participantsScroller")[0];
        const gallery_item_size = participantsScroller.querySelector('img').clientWidth;
        participantsScroller.scrollBy(-gallery_item_size, 0);
    }

    return (
        <div className = "participants">
            <h2>Katılımcı Şirketler</h2>
            <div className = "participantsButtonContainer">
                <p className = {`participantsButton ${option === 1 ? "participantsButtonActive" : ""}`} onClick = {()=> setOption(1)}>13. E&G Kampı</p>
                <p className = {`participantsButton ${option === 2 ? "participantsButtonActive" : ""}`} onClick = {()=> setOption(2)}>14. E&G Kampı</p>
            </div>

            <div className = "participantCompanies">
                <img onClick = {scrollToPrevPage} src = {arrowLeft} alt = "previous"/>
                <div className = "participantsScroller">
                    {option === 1
                        ?
                            <>
                                <img src = {Logo1}  alt = "Akkim" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo2}  alt = "Allianz" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo4}  alt = "AstraZeneca" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo5}  alt = "BAT" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo6}  alt = "Bosch" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo7}  alt = "CCI" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo8}  alt = "Colgate" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo9}  alt = "ÇimSA" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo10} alt = "EnerjiSA" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo11} alt = "Eti" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo12} alt = "JTI" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo13} alt = "KEAS" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo14} alt = "KordSA" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo15} alt = "KPMG" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo16} alt = "Metro" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo17} alt = "Oyak Renault" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo18} alt = "Penti" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo19} alt = "PepsiCO" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo20} alt = "PMSA" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo21} alt = "QNB Finansbank" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo22} alt = "Sahibinden" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo23} alt = "Sanofi" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo24} alt = "SistemGlobal" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo25} alt = "Şişecam" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo26} alt = "Tat" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo27} alt = "Tusaş" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo28} alt = "TürkTelekom" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo29} alt = "Unilever" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo30} alt = "Vodafone" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo31} alt = "Yıldız Holding" />
                                <span>{"\u2800"}</span>
                            </>
                        :
                            <>
                                <img src = {Logo1}  alt = "Akkim" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo32}  alt = "Arcelik" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo33}  alt = "Bayer" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo6}  alt = "Bosch" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo5}  alt = "British American Tobacco" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo7}  alt = "CCI" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo8}  alt = "Colgate" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo9}  alt = "ÇimSA" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo34}  alt = "Döhler" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo35}  alt = "Hayat" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo10} alt = "EnerjiSA" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo12} alt = "JTI" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo14} alt = "KordSA" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo36} alt = "Metro" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo37} alt = "Mey-Diageo" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo17} alt = "Oyak Renault" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo18} alt = "Penti" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo19} alt = "PepsiCO" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo20} alt = "PMSA" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo39} alt = "PWC" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo38} alt = "Perfetti Van Melle" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo21} alt = "QNB Finansbank" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo22} alt = "Sahibinden" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo25} alt = "Şişecam" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo40} alt = "TEI" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo27} alt = "Tusaş" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo29} alt = "Unilever" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo30} alt = "Vodafone" />
                                <span>{"\u2800"}</span>
                                <img src = {Logo31} alt = "Yıldız Holding" />
                                <span>{"\u2800"}</span>
                            </>
                    }
                </div>
                <img onClick = {scrollToNextPage} src = {arrowRight} alt = "next"/>
            </div>
        </div>
    )
}

export default Participants;