import React, {useState} from "react";
import arrowLeft                    from "../../Media/arrowLeft.png";
import arrowRight                   from "../../Media/arrowRight.png";
import {LazyLoadImage}              from "react-lazy-load-image-component"
import playButton                   from "../../Media/PlayButton.png";
import vid1                         from "../../Media/Gallery/vid1.mp4";
import vid1Thumbnail                from "../../Media/Gallery/vid1Thumbnail.png";
import image1                       from "../../Media/Gallery/1.webp";
import image2                       from "../../Media/Gallery/2.webp";
import image3                       from "../../Media/Gallery/3.webp";
import image4                       from "../../Media/Gallery/4.webp";
import image5                       from "../../Media/Gallery/5.webp";
import image6                       from "../../Media/Gallery/6.webp";
import image7                       from "../../Media/Gallery/7.webp";
import image8                       from "../../Media/Gallery/8.webp";
import image9                       from "../../Media/Gallery/9.webp";
import image10                      from "../../Media/Gallery/10.webp";
import image11                      from "../../Media/Gallery/11.webp";
import image12                      from "../../Media/Gallery/12.webp";
import image13                      from "../../Media/Gallery/13.webp";
import image14                      from "../../Media/Gallery/14.webp";
import image15                      from "../../Media/Gallery/15.webp";
import image16                      from "../../Media/Gallery/16.webp";
import image17                      from "../../Media/Gallery/17.webp";
import image18                      from "../../Media/Gallery/18.webp";
import image19                      from "../../Media/Gallery/19.webp";
import image20                      from "../../Media/Gallery/20.webp";
import image21                      from "../../Media/Gallery/21.webp";
import image22                      from "../../Media/Gallery/22.webp";
import image23                      from "../../Media/Gallery/23.webp";
import image24                      from "../../Media/Gallery/24.webp";
import image25                      from "../../Media/Gallery/25.webp";
import image26                      from "../../Media/Gallery/26.webp";
import image27                      from "../../Media/Gallery/27.webp";
import image28                      from "../../Media/Gallery/28.webp";
import image29                      from "../../Media/Gallery/29.webp";
import image30                      from "../../Media/Gallery/30.webp";
import image31                      from "../../Media/Gallery/31.webp";
import image32                      from "../../Media/Gallery/32.webp";
import image33                      from "../../Media/Gallery/33.webp";
import image34                      from "../../Media/Gallery/34.webp";
import image35                      from "../../Media/Gallery/35.webp";
import image36                      from "../../Media/Gallery/36.JPG";
import image37                      from "../../Media/Gallery/37.JPG";
import image38                      from "../../Media/Gallery/38.JPG";
import image39                      from "../../Media/Gallery/39.JPG";
import image40                      from "../../Media/Gallery/40.JPG";
import image41                      from "../../Media/Gallery/41.JPG";
import image42                      from "../../Media/Gallery/42.JPG";
import image43                      from "../../Media/Gallery/43.JPG";
import image44                      from "../../Media/Gallery/44.JPG";
import "./PastToToday.css";

const PastToToday = () => {
    const imagesList = [image44, image36, image37, image38, image39, image40, image41, image42, image43, image1, image2, image3, image4, image5, vid1, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28, image29, image30, image31, image32, image33, image34, image35];
   
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [image,             setImage]             = useState(imagesList[0]);
    const [isImage,           setIsImage]           = useState(true);

    function scrollToNextPage(){
        if(currentImageIndex+1 < imagesList.length){
            console.log(imagesList[currentImageIndex+1].slice(-3) !== "mp4");
            if(imagesList[currentImageIndex+1].slice(-3) !== "mp4"){
                setIsImage(true);
            }else{
                setIsImage(false);
            }
            setCurrentImageIndex((i)=> i+1);
            setImage(imagesList[currentImageIndex+1]);
            const gallery = document.getElementsByClassName("gallery")[0];
            const galleryScroller = gallery.getElementsByClassName("galleryScroller")[0];
            const gallery_item_size = galleryScroller.querySelector("div").clientWidth;
            galleryScroller.scrollBy(gallery_item_size + 44, 0);
        }
    }

    function scrollToPrevPage() {
        if(currentImageIndex-1>=0){
            if(imagesList[currentImageIndex-1].slice(-3) !== "mp4"){
                setIsImage(true);
            }else{
                setIsImage(false);
            }
            setCurrentImageIndex((i)=> i-1);
            setImage(imagesList[currentImageIndex-1]);
            const gallery = document.getElementsByClassName("gallery")[0];
            const galleryScroller = gallery.getElementsByClassName("galleryScroller")[0];
            const gallery_item_size = galleryScroller.querySelector("div").clientWidth;
            galleryScroller.scrollBy(-gallery_item_size + 44, 0);
        }
    }

    const clickHandler = (image, i) =>{
        console.log(i);
        if(image.substring(image.lastIndexOf(".")+1) !== "mp4"){
            setIsImage(true);
            console.log("image");
        }else{
            setIsImage(false);
            console.log("video");
        }
        setImage(image);
        setCurrentImageIndex(i);
    }

    return (
        <div className = "pastToToday">
            <h2>Dünden Bugüne Etkileşim&Gelişim Kampı’nda Neler Oldu?</h2>
            
            <div className = "gallery">
                <div className = "showcaseContainer">
                    <img className = "noSelect" onClick = {scrollToPrevPage} src = {arrowLeft} alt = "previous"/>
                    <div className = "showcaseImage">
                        {
                            isImage
                                ?   <img className = "noSelect" src = {image} alt = ""/>
                                
                                :   <video className = "noSelect" src = {image} alt = "" controls> 
                                        <source src = {image} type = "video/mp4"/>
                                    </video>
                        }
                    </div>
                    <img className = "noSelect" onClick = {scrollToNextPage} src = {arrowRight} alt = "next"/>
                </div>
                <div className = "galleryScroller">
                    {   
                        imagesList.map((image, i)=>{
                            if(image.substring(image.lastIndexOf(".")+1) === "webp" || image.substring(image.lastIndexOf(".")+1) === "JPG" || image.substring(image.lastIndexOf(".")+1) === "png"){
                                return  <div className = "noSelect" key = {i}>
                                            <img className = "noSelect" src = {image} alt = "" onClick = {()=> clickHandler(image, i)}/>
                                        </div>
                            }else{
                                return  <div className = "videoImage noSelect" key = {i} onClick = {()=> clickHandler(image, i)} style = {{ backgroundImage: `url(${vid1Thumbnail})`,
                                                                                                                                            backgroundRepeat:     "no-repeat",
                                                                                                                                            backgroundSize:       `cover`,
                                                                                                                                         }}>
                                            <img className = "video noSelect" src = {playButton} alt = "" />
                                        </div>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default PastToToday;