import React, {useState}    from 'react';
import Logo                 from "../../Media/NavbarLogo.png"
import FacebookIcon         from "../../Media/FacebookIcon.png"
import LinkedInIcon         from "../../Media/LinkedInIcon.png"
import InstagramIcon        from "../../Media/InstagramIcon.png"
import TwitterIcon          from "../../Media/TwitterIcon.png"
import "./Navbar.css";

const Navbar = ({smoothScroll}) => {
    const [isMenuActive, setIsMenuActive] = useState(false);

    const handleMenuClick = () =>{
        if(isMenuActive){
            setIsMenuActive(false);
        }else{
            setIsMenuActive(true);
        }
    }

    const handleItemClick = (element) =>{
            setIsMenuActive(false);
            smoothScroll(element);
    }

    return (
        <nav>
            <img src =  {Logo} className = "navLogo" alt = "Etkileşim ve Gelişim Kampı" onClick = {()=> smoothScroll(document.getElementsByClassName("header")[0])}/>
            <div className = "mobileNavBar">
                <div onClick = {handleMenuClick} className = {`hamburgerMenu ${isMenuActive ? "hamburgerMenuActive" : ""}`}>
                    <div className = "barTop"></div>
                    <div className = "barMiddle"></div>
                    <div className = "barBottom"></div>
                </div>
                <ul className = {`mobileNavBarLinksContainer ${isMenuActive ? "mobileNavBarLinksContainerActive" : ""}`}>
                    <li onClick = {()=> handleItemClick(document.getElementsByClassName("whatExplainer")[0])}>Hakkında</li>
                    <li onClick = {()=> handleItemClick(document.getElementsByClassName("participants")[0])}>Katılımcılar</li>
                    <li onClick = {()=> handleItemClick(document.getElementsByClassName("pastToToday")[0])}>Dünden Bugüne E&G Kamp</li>
                    <li onClick = {()=> handleItemClick(document.getElementsByTagName("footer")[0])}>İletişim</li>
                    <li>
                        <a target = "_blank" rel="noreferrer" href = "https://www.facebook.com/3ik.org">     <img src = {FacebookIcon}  alt = "Facebook"/></a>
                        <a target = "_blank" rel="noreferrer" href = "https://www.linkedin.com/company/3ik/"><img src = {LinkedInIcon}  alt = "LinkedIn"/></a>
                        <a target = "_blank" rel="noreferrer" href = "https://www.instagram.com/3ik_tr/">    <img src = {InstagramIcon} alt = "Instagram"/></a>
                        <a target = "_blank" rel="noreferrer" href = "https://twitter.com/3ik_tr">           <img src = {TwitterIcon}   alt = "Twitter"/></a>
                    </li>
                </ul>
            </div>
            <ul id = "navBarLinksContainer">
                <li onClick = {()=> smoothScroll(document.getElementsByClassName("whatExplainer")[0])}>Hakkında</li>
                <li onClick = {()=> smoothScroll(document.getElementsByClassName("participants")[0])}>Katılımcılar</li>
                <li onClick = {()=> smoothScroll(document.getElementsByClassName("pastToToday")[0])}>Dünden Bugüne E&G Kamp</li>
                <li onClick = {()=> smoothScroll(document.getElementsByTagName("footer")[0])}>İletişim</li>
            </ul>
            <div id = "navBarSocialsContainer">
                <a target = "_blank" rel="noreferrer" href = "https://www.facebook.com/3ik.org">     <img src = {FacebookIcon}  alt = "Facebook"/></a>
                <a target = "_blank" rel="noreferrer" href = "https://www.linkedin.com/company/3ik/"><img src = {LinkedInIcon}  alt = "LinkedIn"/></a>
                <a target = "_blank" rel="noreferrer" href = "https://www.instagram.com/3ik_tr/">    <img src = {InstagramIcon} alt = "Instagram"/></a>
                <a target = "_blank" rel="noreferrer" href = "https://twitter.com/3ik_tr">           <img src = {TwitterIcon}   alt = "Twitter"/></a>
            </div>
        </nav>
    )
}

export default Navbar;



                