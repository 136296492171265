import React, {useEffect}   from 'react';
import BannerItem1          from '../../Media/Header/Bag.png';
import BannerItem2          from '../../Media/Header/Ball.png';
import BannerItem3          from '../../Media/Header/Hands.png';
import BannerItem4          from '../../Media/Header/Microphone.png';
import BannerItem5          from '../../Media/Header/Shape2.png';
import BannerItem6          from '../../Media/Header/Star1.png';
import BannerItem7          from '../../Media/Header/Star2.png';
import BannerItem8          from '../../Media/Header/Star3.png';
import BannerItem9          from '../../Media/Header/Star4.png';
import BannerItem10         from '../../Media/Header/Star5.png';
import BannerItem11         from '../../Media/Header/Triangle1.png';
import BannerItem12         from '../../Media/Header/Triangle2.png';
import BannerItem13         from '../../Media/Header/Triangle3.png';
import BannerItem14         from '../../Media/Header/Triangle4.png';
import BannerItem15         from '../../Media/Header/Triangle5.png';
import BannerItem16         from '../../Media/Header/Date.png';
import Logo                 from "../../Media/3IKLogo.png"
import LocationIcon         from "../../Media/LocationIcon.png"
import IframeBG             from "../../Media/EgKampIFrame.png"
import "./Header.css";

const Header = (props) => {
    useEffect(()=>{
        if(props.isSafari()){
            document.getElementsByClassName("bannerImage")[0].style.animation = "8s ease-in-out forwards infinite headerAnimSafari"
            document.getElementsByClassName("bannerImagePart16")[0].style.animation = "8s ease-in-out forwards infinite headerAnimSafari"
        }

        Array.from(document.getElementsByClassName("bannerImagePart")).map((part)=>{
            part.style.animation = `headerPartAnim ${Math.floor(Math.random() * (9 - 7) + 7)}s ease-in-out forwards infinite ${Math.floor(Math.random() * 4)}s`
        })

        document.getElementsByClassName("bannerImagePart16")[0].style.animation = "8s ease-in-out forwards infinite headerAnim"
    }, [])

    const mouseMoveHandler = (e) =>{
        var cvalueX= (e.pageX * -1 / 40);
        var cvalueY= (e.pageY * -1 / 60);

        Array.from(document.getElementsByClassName("parallax1")).map((part)=>{
            part.style.transform = `translate(${cvalueX}px, ${cvalueY * 2}px)`;
        })

        Array.from(document.getElementsByClassName("parallax2")).map((part)=>{
            part.style.transform = `translate(${cvalueX * 2}px, ${cvalueY * 2}px)`;
        })

        Array.from(document.getElementsByClassName("parallax4")).map((part)=>{
            part.style.transform = `translate(${cvalueX * 4}px, ${cvalueY * 4}px)`;
        })

        document.getElementsByClassName("bannerImagePart5")[0].style.transform = `translate(${cvalueX*1}px, ${cvalueY}px) scale(1.5)`;
    }


    /*FOR IFRAME*/
    // const submitHandler = (e) =>{
    //     e.preventDefault();
    //     if(document.getElementById("Password").value === "egkamp2022"){
    //         document.getElementById("iframe").style.display = "flex";
    //         document.getElementById("passwordContainer").style.display = "none";
    //     }
    // }

    return (
        <div className = "header">
            <div className = "bannerImage" onMouseMove = {mouseMoveHandler}>
                <img className = "bannerImagePart bannerImagePart1  parallax1 noSelect" src = {BannerItem1}  alt = "" />
                <img className = "bannerImagePart bannerImagePart2  parallax1 noSelect" src = {BannerItem2}  alt = "" />
                <img className = "bannerImagePart bannerImagePart3  parallax1 noSelect" src = {BannerItem3}  alt = "" />
                <img className = "bannerImagePart bannerImagePart4  parallax1 noSelect" src = {BannerItem4}  alt = "" />
                <img className = "bannerImagePart bannerImagePart5            noSelect" src = {BannerItem5}  alt = "" />
                <img className = "bannerImagePart bannerImagePart6  parallax1 noSelect" src = {BannerItem6}  alt = "" />
                <img className = "bannerImagePart bannerImagePart7  parallax1 noSelect" src = {BannerItem7}  alt = "" />
                <img className = "bannerImagePart bannerImagePart8  parallax1 noSelect" src = {BannerItem8}  alt = "" />
                <img className = "bannerImagePart bannerImagePart9  parallax1 noSelect" src = {BannerItem9}  alt = "" />
                <img className = "bannerImagePart bannerImagePart10 parallax1 noSelect" src = {BannerItem10} alt = "" />
                <img className = "bannerImagePart bannerImagePart11 parallax4 noSelect" src = {BannerItem11} alt = "" />
                <img className = "bannerImagePart bannerImagePart12 parallax2 noSelect" src = {BannerItem12} alt = "" />
                <img className = "bannerImagePart bannerImagePart13 parallax4 noSelect" src = {BannerItem13} alt = "" />
                <img className = "bannerImagePart bannerImagePart14 parallax4 noSelect" src = {BannerItem14} alt = "" />
                <img className = "bannerImagePart bannerImagePart15 parallax4 noSelect" src = {BannerItem15} alt = "" />
                <img className = "bannerImagePart bannerImagePart16 parallax2 noSelect" src = {BannerItem16} alt = "" />
            </div>

            <h1 onMouseMove = {mouseMoveHandler} className = "noSelect">Etkileşim <span className = "yellow"><span className = "bold">&</span></span><br/> 
            
            <span className = "bold">Gelişim Kampı</span></h1>
            
            <a href = "https://goo.gl/maps/GU7r4UKdyRkqJHhZ9" target = "_blank" className = "locationContainer">
                <img src = {LocationIcon} alt = "" />
                <p> Pendik<br/>
                    The Green Park<br/>
                    Hotel</p>
            </a>
            <a href = "https://www.3ik.org" target = "_blank" className = "headerLogo">
                <img src = {Logo} alt="" />
            </a>
        </div>


        // <div className = "header" style = {{justifyContent: "center", alignItems: "center"}}>
        //     <form onSubmit = {submitHandler} style = {{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "32px", width: "30vw", height: "50vh", zIndex: "999"}} id = "passwordContainer">
        //         <input style = {{}} type = "text" placeHolder = "Şifre" name = "" id = "Password" />
        //         <input type = "submit" value = "Giriş" />
        //     </form>

        //     <div style = {{justifyContent: "center", alignContent: "center", position: "relative", zIndex: "501", width: "100vw"}} id = "iframe">
        //         <iframe src="https://vimeo.com/event/2398576/embed" 
        //                 frameBorder ="0" 
        //                 allow       = "autoplay; fullscreen; picture-in-picture"
        //                 style       = {{borderRadius: "16px", marginTop: "6.5vw", width: "71.8vw", height: "41vw"}}
        //                 allowFullScreen/>
        //     </div>
        //     <img id = "iFrameBG" src = {IframeBG} style = {{position: "absolute", zIndex: "500"}} alt = "" />
        // </div>
    )
}

export default Header;