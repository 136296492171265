import React, { useEffect }            from "react";
import gs1              from "../../Media/GlowStick1.png"
import gs2              from "../../Media/GlowStick2.png"
import gs3              from "../../Media/GlowStick3.png"
import gs4              from "../../Media/GlowStick4.png"
import gs5              from "../../Media/GlowStick5.png"
import gs6              from "../../Media/GlowStick6.png"
import gs7              from "../../Media/GlowStick7.png"
import gs8              from "../../Media/GlowStick8.png"
import gs9              from "../../Media/GlowStick9.png"
import gs10             from "../../Media/GlowStick1.png"
import gs11             from "../../Media/GlowStick1.png"
import "./BackgroundLights.css";

const App = (props) => {
    useEffect(()=>{
        if(props.isOnApple()){
            Array.from(document.getElementsByClassName("backgroundLight")).map((light)=>{
                light.style.animation = "4s ease-in-out forwards infinite lightAnimIOSChrome"
            })
        }
        
        if(props.isSafari()){
            Array.from(document.getElementsByClassName("backgroundLight")).map((light)=>{
                light.style.animation = "4s ease-in-out forwards infinite lightAnimSafari"
            })
        }

        Array.from(document.getElementsByClassName("backgroundLight")).map((light)=>{
            light.style.animationDelay = `${Math.random() * 4}s`
        })
    }, [])

    return (
        <div className = "backgroundLights noSelect" style = {{height: `${props.height}`}}>
            <img className = "backgroundLight backgroundLight1 noSelect"  src = {gs1}  alt = "" />
            <img className = "backgroundLight backgroundLight2 noSelect"  src = {gs2}  alt = "" />
            <img className = "backgroundLight backgroundLight3 noSelect"  src = {gs3}  alt = "" />
            <img className = "backgroundLight backgroundLight4 noSelect"  src = {gs4}  alt = "" />
            <img className = "backgroundLight backgroundLight5 noSelect"  src = {gs5}  alt = "" />
            <img className = "backgroundLight backgroundLight6 noSelect"  src = {gs6}  alt = "" />
            <img className = "backgroundLight backgroundLight7 noSelect"  src = {gs7}  alt = "" />
            <img className = "backgroundLight backgroundLight8 noSelect"  src = {gs8}  alt = "" />
            <img className = "backgroundLight backgroundLight9 noSelect"  src = {gs9}  alt = "" />
            <img className = "backgroundLight backgroundLight10 noSelect" src = {gs10} alt = "" />
            <img className = "backgroundLight backgroundLight11 noSelect" src = {gs11} alt = "" />
        </div>
    );
}

export default App;
            
