import React from 'react';
import "./WhatExplainer.css";

const WhatExplainer = () => {
    return (
        <div className = "whatExplainer">
            <h2>Etkileşim&Gelişim Kampı Nedir?</h2>
            <p>Etkileşim&Gelişim Kampı, Türkiye’nin en aktif iş ve kariyer kulüplerinin, Türkiye’nin en gözde şirketleriyle bir araya geldiği 3İK tarafından düzenlenen ilk ve tek kulüp-şirket buluşma kampıdır.</p>
        </div>
    )
}

export default WhatExplainer;