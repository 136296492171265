import React        from 'react';
import logo         from '../../Media/3IKLogo.png'; 
import locationIcon from '../../Media/locationIconFooter.png'; 
import mailIcon     from '../../Media/mailIcon.png'; 
import "./Footer.css";

const Footer = () => {
    return (
        <footer>
            <div className = "footerTextContainer">
                <p>Etkileşim&Gelişim Kampı bir</p>
                <a href = "https://www.linkedin.com/company/3ik/?originalSubdomain=tr">
                    <img src = {logo} alt = "3İK" />
                </a>
                <p>projesidir.</p>
            </div>
            <div className = "footerInfoContainerOuter">
                <a href = "mailto:info@3ik.org" className = "footerInfoContainerInner">
                    <img src = {mailIcon} alt = ""/>
                    <p>info@3ik.org</p>
                </a>
                <a href = "https://goo.gl/maps/Q7p788oA87PkavE99" target = "_blank" className = "footerInfoContainerInner">
                    <img src = {locationIcon} alt = "" />
                    <p>Levent Mah. Üstzeren Sok. No: 2 Levent / İstanbul</p>
                </a>
            </div>
        </footer>
    )
}

export default Footer;



                


