import React, { useEffect, useState }            from "react";
import Background       from "./Media/Background.png";
import Navbar           from "./Components/Navbar/Navbar";
import Header           from "./Components/Header/Header";
import WhatExplainer    from "./Components/WhatExplainer/WhatExplainer";
import WhyExplainer     from "./Components/WhyExplainer/WhyExplainer";
import WhatIsInIt       from "./Components/WhatIsInIt/WhatIsInIt";
import Numbers          from "./Components/Numbers/Numbers";
import Participants     from "./Components/Participants/Participants";
import WhatTheySay      from "./Components/WhatTheySay/WhatTheySay";
import PastToToday      from "./Components/PastToToday/PastToToday";
import Footer           from "./Components/Footer/Footer";
import BackgroundLights from './Components/BackgroundLights/BackgroundLights';
import "./App.css";
// import "./IFRAME.css";

const App = () => {
    function isOnApple(){
        return /^((?!chrome|android).)*Macintosh/i.test(navigator.userAgent);
    }

    function isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    const [documentHeight,  setDocumentHeight]  = useState("6000px")

    useEffect(()=>{
        setDocumentHeight(getComputedStyle(document.getElementsByClassName("App")[0]).height)
    }, [])

    const smoothScroll = (target) =>{
        const { top } = target.getBoundingClientRect()
        window.scrollTo({
          top: top - 88 + window.pageYOffset,
          behavior: "smooth"
        });
      }
    
    return (
        <div className = "App" style = {{ backgroundImage:      `url(${Background})`,
                                          backgroundRepeat:     "repeat-y",
                                          backgroundSize:       `120vw ${documentHeight}`,
                                          animation:            "1s ease-in-out forwards infinite backgroundAnim"
                                        }}>
            <BackgroundLights height = {documentHeight} isSafari = {isSafari} isOnApple = {isOnApple}/>
            <Navbar smoothScroll = {smoothScroll}/>
            <Header isSafari = {isSafari}/>
            <WhatExplainer/>
            <WhyExplainer/>
            <WhatIsInIt/>
            <Numbers isSafari = {isSafari} isOnApple = {isOnApple}/>
            <Participants/>
            <WhatTheySay/>
            <PastToToday/>
            <Footer/>
        </div>
    );
}

export default App;