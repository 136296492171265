import React            from 'react';
import "./WhatIsInIt.css";

const WhatIsInIt = () => {    //ADD SPANS TO MAKE BOLD
    return (
        <div className = "whatIsInIt">
            <h2>Etkileşim&Gelişim Kampı’nda Neler Var?</h2>
            <div className = "whatIsInItContainerOuter">
                <div className = "whatIsInItContainerInner">
                    <div><p>Şirket Sunumları</p></div>
                    <div><p>Eğitimler</p></div>
                    <div><p>Eğlenceli Aktiviteler</p></div>
                </div>
                <div className = "whatIsInItContainerInner">
                    <div><p>İnteraktif Oturumlar</p></div>
                    <div><p>Vaka Analizleri</p></div>
                    <div><p>Atölyeler</p></div>
                </div>
            </div>
            <p className = "whatIsInItText">Kampta kulüp temsilcileri katıldıkları oturumlarda şirket yetkililerinin sunumları ile vizyonlarını genişletme imkanına sahip olurken, şirketler Türkiye’nin en aktif iş ve kariyer kulüplerinin temsilcileri ile samimi bir bağ kurma fırsatı yakalıyor.</p>
        </div>
    )
}

export default WhatIsInIt;