import React, {useEffect} from 'react';
import "./Numbers.css";

const Numbers = (props) => {
    const destinationNumbers = [88 , 29, 19, 41];

    useEffect(()=>{
        const handleKeyDown = () =>{
            const rect = document.getElementsByClassName("numberInContainer")[0].getBoundingClientRect();

            if( rect.top >= 0 && 
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            ){
                const thisInterval = setInterval(()=>{
                    if(Number(document.getElementsByClassName("numberInContainer")[0].textContent) < destinationNumbers[0]){
                        document.getElementsByClassName("numberInContainer")[0].textContent = Number(document.getElementsByClassName("numberInContainer")[0].textContent) + 1
                    }
        
                    const numberComp = Number(document.getElementsByClassName("numberInContainer")[1].textContent.split("+")[0]);
                    if(numberComp < destinationNumbers[1]){
                        document.getElementsByClassName("numberInContainer")[1].textContent = numberComp + 1
                    }
                    
                    const numberCity = Number(document.getElementsByClassName("numberInContainer")[2].textContent.split("+")[0]);
                    if(numberCity < destinationNumbers[2]){
                        document.getElementsByClassName("numberInContainer")[2].textContent = numberCity + 1
                    }
        
                    const numberUni = Number(document.getElementsByClassName("numberInContainer")[3].textContent.split("+")[0]);
                    if(numberUni < destinationNumbers[3]){
                        document.getElementsByClassName("numberInContainer")[3].textContent = numberUni + 1;
                    }
        
                    if(!(Number(document.getElementsByClassName("numberInContainer")[0].textContent) < destinationNumbers[0]) && !(numberComp < destinationNumbers[1]) && !(numberCity < destinationNumbers[2]) && !(numberUni < destinationNumbers[3])){
                        clearInterval(thisInterval);
                    }
                }, 220)
            }
        }

        window.addEventListener('scroll', handleKeyDown);

        return () => {
            window.removeEventListener('scroll', handleKeyDown);
        };
    }, []);

          
    useEffect(()=>{
        if(props.isOnApple()){
            Array.from(document.getElementsByClassName("numberInContainer")).map((number)=>{
                number.style.animation = "10s ease-in-out forwards infinite numberLightAnimIOSChrome"
            })
        }
        
        if(props.isSafari()){
            Array.from(document.getElementsByClassName("numberInContainer")).map((number)=>{
                number.style.animation = "11s ease-in-out forwards infinite numberLightAnimSafari"
            })
        }
    },[])

    return (
        <div className = "numbers">
            <h2>7-10 Eylül 2022</h2>
            <div className = "numbersContainerOuter">
                <div className = "numbersContainerInner">
                    <div>
                        <p className = "numberInContainer">10</p>
                    </div>
                    <p className = "textInNumbersContainer">İŞ&KARİYER KULÜBÜ</p>
                </div>
                <div className = "numbersContainerInner">
                    <div>
                        <p className = "numberInContainer">10+</p>
                    </div>
                    <p className = "textInNumbersContainer">ŞİRKET</p>
                </div>
                <div className = "numbersContainerInner">
                    <div>
                        <p className = "numberInContainer">10+</p>
                    </div>
                    <p className = "textInNumbersContainer">ŞEHİR</p>
                </div>
                <div className = "numbersContainerInner">
                    <div>
                        <p className = "numberInContainer">10+</p>
                    </div>
                    <p className = "textInNumbersContainer">ÜNİVERSİTE</p>
                </div>
            </div>
            <p className = "numbersText">Hem şirketler hem de kulüpler açısından yeni eğitim-öğretim dönemine başlangıç etkinliği olarak görülen ve bu sene 14. kez gerçekleşecek olan Etkileşim&Gelişim Kampı’nda 3 yıl aradan sonra fiziksel olarak buluşuyoruz!</p>
        </div>
    )
}

export default Numbers;