import React, { useState }  from 'react';
import Pfizer               from "../../Media/WTSLogos/Pfizer.png"
import Anadolu              from "../../Media/WTSLogos/Anadolu.png"
import CCI                  from "../../Media/WTSLogos/CCI.png"
import Golf                 from "../../Media/WTSLogos/Golf.png"
import JTI                  from "../../Media/WTSLogos/JTI.png"
import Migros               from "../../Media/WTSLogos/Migros.png"
import PG                   from "../../Media/WTSLogos/PG.png"
import Penti                from "../../Media/WTSLogos/Penti.png"
import PMI                  from "../../Media/WTSLogos/PMI.png"
import QNB                  from "../../Media/WTSLogos/QNB.png"
import Sahibinden           from "../../Media/WTSLogos/Sahibinden.png"
import Sisecam              from "../../Media/WTSLogos/Sisecam.png"
import Akkim                from "../../Media/WTSLogos/Akkim.png"
import Arcelik              from "../../Media/WTSLogos/Arcelik.png"
import BATTR                from "../../Media/WTSLogos/BATTR.png"
import Bayer                from "../../Media/WTSLogos/Bayer.png"
import Bosch                from "../../Media/WTSLogos/Bosch.png"
import Colgate              from "../../Media/WTSLogos/Colgate.png"
import Cimsa                from "../../Media/WTSLogos/Cimsa.png"
import Dohler               from "../../Media/WTSLogos/Dohler.png"
import EnerjiSa             from "../../Media/WTSLogos/EnerjiSa.png"
import Hayat                from "../../Media/WTSLogos/Hayat.png"
import Kordsa               from "../../Media/WTSLogos/Kordsa.png"
import Metro                from "../../Media/WTSLogos/Metro.png"
import OyakRenault          from "../../Media/WTSLogos/OyakRenault.png"
import PepsiCo              from "../../Media/WTSLogos/PepsiCo.png"
import Perfetti             from "../../Media/WTSLogos/Perfetti.png"
import PWC                  from "../../Media/WTSLogos/PWC.png"
import TEI                  from "../../Media/WTSLogos/TEI.png"
import Tusas                from "../../Media/WTSLogos/Tusas.png"
import Unilever             from "../../Media/WTSLogos/Unilever.png"
import YildizHolding        from "../../Media/WTSLogos/YildizHolding.png"
import Vodafone             from "../../Media/WTSLogos/Vodafone.png"
import Cukurova             from "../../Media/WTSLogos/Cukurova.png"
import Duzce                from "../../Media/WTSLogos/Duzce.png"
import Eskisehir            from "../../Media/WTSLogos/Eskisehir.png"
import HacettepeEkonomi     from "../../Media/WTSLogos/HacettepeEkonomi.png"
import HacettepeIsletme     from "../../Media/WTSLogos/HacettepeIsletme.png"
import HacettepeACM         from "../../Media/WTSLogos/HacettepeACM.png"
import HacettepeGirisim     from "../../Media/WTSLogos/HacettepeGirisim.png"
import HacettepeIEEE        from "../../Media/WTSLogos/HacettepeIEEE.png"
import Karadeniz            from "../../Media/WTSLogos/Karadeniz.png"
import SabanciEkonomi       from "../../Media/WTSLogos/SabanciEkonomi.png"
import SabanciEndustri      from "../../Media/WTSLogos/SabanciEndustri.png"
import Sakarya              from "../../Media/WTSLogos/Sakarya.png"
import arrowLeft            from '../../Media/arrowLeft.png';
import arrowRight           from '../../Media/arrowRight.png';
import "./WhatTheySay.css";

const WhatTheySay = () => {
    const [more, setMore] = useState(false);
    function scrollToNextPage() {
        const gallery = document.getElementsByClassName("whatTheySayContainer")[0];
        const galleryScroller = gallery.getElementsByClassName("whatTheySayScroller")[0];
        const styleSheet = getComputedStyle(galleryScroller);
        const gallery_item_size = galleryScroller.querySelector('div').clientWidth + Number(styleSheet.gap.split("px")[0]) + (window.innerWidth > 900 ? 0 : 15.5);
        galleryScroller.scrollBy(gallery_item_size, 0);
        console.log(gallery_item_size);
    }

    function scrollToPrevPage() {
        const gallery = document.getElementsByClassName("whatTheySayContainer")[0];
        const galleryScroller = gallery.getElementsByClassName("whatTheySayScroller")[0];
        const styleSheet = getComputedStyle(galleryScroller);
        const gallery_item_size = galleryScroller.querySelector('div').clientWidth + Number(styleSheet.gap.split("px")[0]) + 15.5;
        galleryScroller.scrollBy(-gallery_item_size, 0);
    }

    const readMoreClickHandler = () =>{
        Array.from(document.getElementsByClassName("whatTheySayContainerInner")).map((element)=>{
            if(window.innerWidth > 640){
                element.style.height    = "670px"
                element.style.minHeight = "670px"
            }else if(window.innerWidth > 500){
                element.style.height    = "520px"
                element.style.minHeight = "520px"
            }else if(window.innerWidth > 400){
                element.style.height    = "710px"
                element.style.minHeight = "710px"
            }else if(window.innerWidth > 340){
                element.style.height    = "790px"
                element.style.minHeight = "790px"
            }else{
                element.style.height    = "670px"
                element.style.minHeight = "670px"
            }
        })

        setTimeout(() => {
            setMore(true);
        }, 400);
    }

    const texts = [
        {   
            Text: "Çok yakından takip ettiğim programa tekrar katıldığım için çok mutluyum. \nİlk başlardaki amatör ruhun kaybolmadan devam ettiği, profesyonelleşmenin çok iyi olduğu bir ilerleme beni çok mutlu etti. Emeği geçen tüm 3İK ekibine çok teşekkür ediyorum.",
            Name: "Gökhan Ata Okutan",
            Logo: Akkim
        },{
            Text: "3İK Ekibi her zaman içimizden biri gibi hissettirdi. Bir müşteri mantığı değil, paydaş olarak tüm sorunları da güzel anıları da bölüştük. Bu çok kıymetliydi. Burada gücünüzü en iyi şekilde ortaya koydunuz. \nBu değerli arkadaşlarımıza ulaşmamızı sağladığınız için çok teşekkürler! \nHep yanımızda kalmaya devam edin güzel insanlar!",
            Name: "Özlem Alimuto",
            Logo: Arcelik
        },{
            Text: "3İK ekibine bizi bu güzel etkinlikte öğrencilerle bir araya getirdikleri için çok çok teşekkür ediyorum. Seneye de güzel günlerde birlikte olabilmek dileğiyle.",
            Name: "Merve Ceren Büyükyabat",
            Logo: BATTR
        },{
            Text: "Bu değerli ve güzel etkinlikte bizi bir araya getirdiği için 3İK’ya çok teşekkür ediyoruz.",
            Name: "İnsan Kaynakları Ekibi",
            Logo: Bayer
        },{  
            Text: "Bugün burada olmak çok güzeldi. \nÖğrencilerin heyecanı ortama yansıyor doğal olarak ve bunu hissetmek gerçekten çok değerli. Bizi bir araya getiren 3İK ekibine çok sevgiler…",
            Name: "Erdoğan Aksoy",
            Logo: Bosch
        },{  
            Text: "Etkileşim&Gelişim Kampı’na 2014’ten beri katılım gösteriyoruz. Özellikle fiziksel olanların enerjisi çok yüksek. İnsanların gülümsemelerini görmek çok kıymetli. 3İK’yı çok seviyorum, onlar benim ailem gibi.",
            Name: "Şive Yılmaz",
            Logo: Colgate
        },{  
            Text: "3İK Ekibine ve tüm kulüp temsilcilerine bu güzel organizasyon için teşekkür ederiz. Yıl boyunca farklı etkinliklerde görüşmek dileğiyle.",
            Name: "Ecem Kavrak",
            Logo: BATTR
        },{  
            Text: "Öncelikle, bu içten ve samimi karşılama için değerli 3İK ekibine çok teşekkür ediyorum.",
            Name: "Eylül Betül Yılmazkor",
            Logo: Cimsa
        },{  
            Text: "Sevgili 3İK Ekibi, \nBir kere daha Etkileşim&Gelişim Kampı’nda sizlerle, öğrencilerle bir arada olmanın mutluluğunu yaşattınız! \nTeşekkür ederiz. \nCCI Yetenek Yönetim Ekibi olarak 3İK’nın bizim için ayrı bir yeri var. Bir arada olacağımız nice güzel buluşmalar, projeler dilerim.",
            Name: "İpek Yöndem",
            Logo: CCI
        },{   
            Text: "Sevgili 3İK Ekibi, \nBu sene ilk kez katıldığımız güzel etkinlikte çok keyif aldık. Nicelerinde görüşmek üzere! \nSizi seviyoruz!",
            Name: "Melike Ardıçer, Gülnur Ekinci",
            Logo: Dohler
        },{   
            Text: "Kariyerlerinin başında, kendi geleceklerine yön vermek için çabalayan genç yetenekler ile bir araya gelmek, onların heyecanlarını ve meraklarını hissetmek, yaratıcı fikirlerini dinlemek canlı hissettiren bir deneyimdi. Enerjisa Üretim olarak 3İK ekibine teşekkür ederiz.",
            Name: "İnsan ve Kültür Ekibi",
            Logo: EnerjiSa
        },{
            Text: "3İK’ya bizi ağırladığı için çok teşekkür ederiz. Kendimizi ünlü gibi hissettik. Sizi seviyoruz.",
            Name: "Müge Kaya, Özlem Ceylani",
            Logo: Hayat
        },{
            Text: "Online organizasyonlardaki enerjinizi yüz yüze organizasyonlarda hissetmek bizim için çok değerli. \nBu enerjimizi nice etkinliklere taşımak dileğiyle. \nBizi üniversite gençliği enerjisiyle buluşturduğunuz için ayrıca teşekkürler.",
            Name: "Miray Gönülşen",
            Logo: Kordsa
        },{
            Text: "Sevgili 3İK Büyük Takım, \nÖncelikle profesyonel ve kaliteli etkinlik için çok teşekkürler. \nDemode söylemleri bir kenara bırakırsak mesele memleket meselesi. Bu meselenin ana çözümü ülkenin genç değerlerini elde tutabilmekten ve üretmekten geçtiğine inanıyorum. \nBu sorunsala yaptığınız katkı büyük. \nÇalışmalarınızın sürdürülebilir olmasını dilerim.",
            Name: "Elif Karaca",
            Logo: Metro
        },{
            Text: "Değerli 3İK Ekibi \nYine şahane bir etkinlikte bizleri kulüp temsilcileriyle buluşturduğunuz için teşekkürler. \nUzun zaman sonra yeteneklerimizle buluşmak çok iyi geldi. \nSağlıkla kalın.",
            Name: "Elif Tayan",
            Logo: OyakRenault
        },{
            Text: "3İK ekibine bizi öğrencilerle buluşturduğu için çok teşekkürler! Çok çok keyifliydi.",
            Name: "Melis Sevingen",
            Logo: PepsiCo
        },{
            Text: "Öncelikle 3İK ekibinde bizi gençlerle buluşturduğu için çok teşekkür ederiz… \nÇok keyifli ve heyecanlı bir etkinlikti. Bir arada olabileceğimiz nice güzel etkinliklere.",
            Name: "Ecem Şimşek, Onur Özsevimli, Nur Banu Güner, Yeşim Zehra Keskin",
            Logo: Perfetti,
            Perfetti: true
        },{
            Text: "Sizi seviyorum. Zaten bunu çok iyi biliyorsunuz. Gençlerle bizi bir araya getirdiğiniz için çok teşekkür ederim. \nDaha yapacak çooook işimiz var. \nSeneye görüşürüz!",
            Name: "Işınsu Kırmızıoğlu",
            Logo: PMI
        },{
            Text: "Sevgili 3İK, \nİyi ki varsınız! 14 yıl önceki heyecanla devam. Her gün daha iyiye birlikte. \nSevgiler.",
            Name: "Yonca Koç",
            Logo: PMI
        },{
            Text: "Buraya ilk geldiğimde ve salonu ilk gördüğümde çok mutlu oldum. Mekana verilen emek olsun, katılımcıların enerjisi olsun çok mutlu bir şekilde ayrılıyorum. \nTeşekkürler 3İK!",
            Name: "Yiğit Timer",
            Logo: PWC
        },{
            Text: "Uzun süren ayrılıklardan sonra tekrar genç yeteneklerle bir araya gelmemizi sağlayan tüm 3İK ekibine teşekkürler. \nGüzel enerjik bir etkinlik oldu. \nEmeği geçen herkesin eline sağlık. \nSevgiler…",
            Name: "İnsan Kaynakları Ekibi",
            Logo: TEI
        },{
            Text: "7. E&G Kampı’ma katılmış oldum ve benim için büyük keyif ve heyecandı. Emeğinize sağlık arkadaşlar. \nYaşasın kulüpçülük! \n#iyiki3İKvar",
            Name: "Muhammed Biral",
            Logo: Tusas
        },{
            Text: "Unilever maceramın başladığı gün sizleri tanımak ve o günden beri en büyük destekçilerimizden biri olmanızın gururunu yaşıyorum. \nFikirlerimizin her zaman yanında olduğunuz için teşekkür ederim!",
            Name: "Sude Nur Demirci",
            Logo: Unilever
        },{
            Text: "Pandemiden dolayı uzun zamandır fiziksel olarak bir araya gelemiyorduk. Tekrar öğrenci kulüpleriyle bir araya gelmek bize de çok iyi geldi. Burada olduğumuz için çok mutluyuz. Teşekkür ederiz.",
            Name: "İnsan Kaynakları Ekibi",
            Logo: YildizHolding
        },{
            Text: "Sevgili 3İK Ekibi,\nSizlerle tanışalı çok uzun zaman olmadı fakat uzun zamandır tanışıp çalışıyor gibi hissediyorum. İşinizdeki profesyonelliğinizin ve samimiyetinizin bugünkü başarınızı getirdiğini düşünüyorum. Daha da iyi ve başarılı projelere imza atacağınızdan eminim.\n Bugün katıldığım 11. Etkileşim & Gelişim Kampı’nda olmaktan çok mutluyum. Daha nice güzel ve verimli iş birlikleri yapmak dileklerimle.\n Sevgiler.",
            Name: "Tuğba Arısan",
            Logo: Pfizer
        },{   
            Text: "Sevgili 3İK,\nBu değerli organizasyonunuzda sizlerle birlikte olma fırsatı verdiğiniz için çok teşekkürler. Bu topluluğu görünce yarınlarla ilgili geleceğe çok daha umutlu bakıyoruz. Birlikte yapacağımız birçok projede sizlerle olmak bizim için büyük bir mutluluk. Bu organizasyonunun uzun seneler devam etmesini diliyorum. Emeği geçen tüm arkadaşlara teşekkür ederiz. ",
            Name: "Ahmet Boran",
            Logo: PG
        },{   
            Text: "Beraber başladığımız bu yolculukta bu günleri görüyor olmak bizim için gurur vesilesi. Öncelikle 3İK’ya ve emeği geçen herkese teşekkür ediyorum. Çok güzel bağlar kurduk, çok güzel dostluklar var. Eminim ki onlarca, yüzlerce, binlerce arkadaşımızla da karşılıklı fayda sağladık. Bu işin en güzel tarafı bu zaten. :)",
            Name: "Cenk Akıncılar",
            Logo: QNB
        },{   
            Text: "6 senedir hem çevrim içi hem de fiziksel olarak bu etkinlikle birlikte öğrencilerle bir araya geliyoruz. Her sene farklı arkadaşlarla bir araya geliyoruz ve onların enerjisi bizi de yeniliyor. Farklı üniversitelerin farklı kulüplerinden öğrencilerle bir araya gelmek bizi inanılmaz besliyor, bu noktada Etkileşim&Gelişim Kampı gerçekten bambaşka bir organizasyon. 3İK ekibine teşekkür ederiz.",
            Name: "İnsan Kaynakları Ekibi",
            Logo: Sahibinden
        },{   
            Text: "Harika enerjiniz ve ‘Usta’lığınız için çok teşekkürler!\n Yine beklediğimiz gibi müthiş bir etkinlik geçirdik! Görüşmek üzere…",
            Name: "Berkan Mifleh",
            Logo: Anadolu
        },{   
            Text: "3İK ekibine her sene bizi kulüp öğrencileriyle buluşturduğu için teşekkür ederiz. Kulüpçülüğe gönül veren öğrencileri şirketlerle buluşturuyorsunuz. Harika etkinlikti!",
            Name: "Ecem Dadalıoğlu",
            Logo: Migros
        },{   
            Text: "Gençlerle birlikte olmaktan, yüz yüze sohbet etmekten enerjilerini hissetmekten büyük mutluluk duydum. 3İK’ya bu harika etkinliği düzenlediği ve Vodafone olarak bizleri de davet ettiği için çok teşekkürler.",
            Name: "Ayça Kahraman",
            Logo: Vodafone
        },{   
            Text: "#İyiki3İKvar\n Hep yanımızda olduğunuz için çok mutluyuz! Daha nice kamplara :)",
            Name: "Naomi Sarkun",
            Logo: PMI
        },{   
            Text: "İlk defa bu organizasyonun içinde yer aldım. Çok profesyonel ve çok keyifli bir ortamdı. Öğrencilerin heyecan ve merakı her şeye değerdi. Yeniden bu organizasyonda yer almak için sabırsızlanıyorum. Her şey için teşekkürler. Sevgilerimle. ",
            Name: "Yasemin Çalışkan",
            Logo: Golf
        },{   
            Text: "Canlarım 3İK Ekibi \nSizlerle birlikte olmak her zamanki gibi muhteşemdi. Nice etkinliklerde birlikte olmak dileğiyle!",
            Name: "Betül Aydın",
            Logo: JTI
        },{   
            Text: "Sevgili 3İK Ailesi, \nE&G Kamp’ta 7. kez sizlerle olmak çok güzel. Sizi çok seviyorum.",
            Name: "Ebru Kahraman",
            Logo: Sisecam
        },{   
            Text: "Sevgili 3İK Ekibi, \nNazik davetiniz ve ev sahipliğiniz için çok teşekkür ederiz. Sizlerle bir arada olmak, genç arkadaşlarımızın gözündeki ışığa heyecanla şahit olmak her zaman çok umutlu.",
            Name: "Gonca Beşli",
            Logo: Penti
        },{   
            Text: "Sevgili 3İK ekibi \nBizi uzun süredir etkinliğe davet ettiğiniz için çok teşekkürler. Çok heyecanlı ve güzel bir deneyimdi bizim için.",
            Name: "Ayşegül Özcömert",
            Logo: JTI
        },{   
            Text: "2 yıl boyunca online geçen etkinliklerden sonra arkadaşlarla yüz yüze olmak ilaç gibi geldi. Üniversiteyi her an özleyen biri olarak arkadaşlarla birlikte olmak o anları tekrar yaşatıyor. \nŞişecam olarak böyle etkinlikleri çok severiz, zaten biliyorsunuz. Devamını bekliyoruz.",
            Name: "Deniz Timincioğlu",
            Logo: Sisecam
        },{   
            Text: "Merhaba, \nBize sunduğunuz fırsatlar ve emekleriniz için çok teşekkür ederim. Türkiye’nin dört bir yanından, \nKulüpçü arkadaşlarımla tanışıp bu sinerjinin bir parçası olmak benim için çok değerli ve çok keyifli oldu. Bir sonraki etkinliklerde görüşmek üzere! \nÇÜEMÖT’ten kucak dolusu sevgilerle!",
            Name: "ÇÜEMÖT - Mehmet Ali Altunay",
            Logo: Cukurova
        },{   
            Text: "Dolu dolu geçen 4 gün için teşekkür ederiz. Sizin sayenizde bir sürü şirket ile bire bir tanışma fırsatı yakaladık. Kariyerimiz için değerli insanlardan güzel tavsiyeler aldık. Bu tavsiyeleri en güzel şekilde değerlendireceğiz. Gelecekte yapacağımız etkinliklerde sizinle iletişimde olmak ve güzel sonuçlar elde etmek dileğiyle. \nGörüşmek üzere!",
            Name: "DÜKAT - Nihal İnci",
            Logo: Duzce
        },{   
            Text: "Bu güzel etkinlikte birçok değer kazandık. Bu denli büyük bir etkinliğin bu şekilde kusursuzca yapılması biz öğrenci temsilcileri açısından da çok değerli oldu. \nİyi ki 3İK var sözünün bu kadar anlam kazandığı bir etkinliğin paydaşı olmaktan, EESTEC Eskişehir temsilcisi olarak katılmaktan kıvanç duydum. Aslında bu kalplere dokunan ve bir araya getiren bu ekibe, katılımcı firmalara, konuşmacı temsilcilere ilk olarak kendim sonra temsil ettiğim EESTEC LC Eskişehir adına teşekkürü bir borç bilirim. \n#iyiki3İKvar",
            Name: "EESTEC - Muhittin Özer",
            Logo: Eskisehir
        },{   
            Text: "İnanılmaz yoğun ama bir o kadar da verimli bu kamp için 3İK (büyük harflerle) ailesine teşekkür ederiz! İnanılmaz insanlar tanıdığım için de çok mutluyum. Hacettepe, Ankara’ya yolunuz düşerse beklerim! ",
            Name: "HEK - Nazlı Akar",
            Logo: HacettepeEkonomi
        },{   
            Text: "Çok güzel arkadaşlar edindiğim, iletişim ağımı genişlettiğim ve kariyer alanında hem topluluğuma hem de bana büyük etkisi olan dolu dolu bir etkinlik yaşadık. Emekleri ve bizleri böyle güzel bir etkinliğe dahil ettikleri için 3İK ailesine çok teşekkür ederim! \nİyi ki 3İK var!",
            Name: "HİT - Göksu Şenyurt",
            Logo: HacettepeIsletme
        },{   
            Text: "Hiç tanımadığım insanlarla tanışıp böyle bir organizasyonda yer aldığım için çok şanslıyım.  Kendim ve kulübüm adına çok teşekkür ederim. Sizleri çok seviyorum. \n#iyiki3İKvar",
            Name: "ACM Hacettepe",
            Logo: HacettepeACM
        },{   
            Text: "Online dönemde başladığım kulüpçülüğe böyle bir etkinlikte devam etme şansı yakaladığım için çok mutlu hissediyorum. Bu kadar kısa sürede çok insanla tanışıp çok verimli bilgiler öğrenmemize yardımcı oldukları için 3İK ekibine çok teşekkür ederim. \n#iyiki3İKvar",
            Name: "HÜGYT",
            Logo: HacettepeGirisim
        },{   
            Text: "Böyle güzel bir etkinlikte bulunmak benim için çok hoş bir deneyimdi. Benzer amaçlar için koşuşturan birçok arkadaşımla tanışma, konuşma, fikir edinme; birbirinden değerli şirketlerle bire bir temas kurmak gibi birçok içerik ile çok kaliteli 4 gün geçirdim. \nİlk gününden son gününe kadar kendimizi özel hissettiğimiz, güler yüzleri ile her an yanımızda bulunan değerli 3İK ekibine ve birçok şehirden bu etkinliğimiz için buluşmak üzere gelen arkadaşlarıma kendim ve temsilcisi olduğum IEEE Hacettepe adına sonsuz teşekkürlerimi sunarım. \nİyi ki kulüpçüler var. İyi ki 3İK var.",
            Name: "HÜIEEE - Melikşah Eryılmaz",
            Logo: HacettepeIEEE
        },{   
            Text: "Bu etkinliği düzenlediği için 3İK’ya teşekkür ederim. Çok güzel ve yararlı bir etkinlik kulüplerin şirketlerle bir araya gelmesi kulüpler açısından çok önemli. \nİletişim ağını genişletmenin yanında güzel arkadaşlıkların da kazanıldığı bu kampta bulunmaktan çok memnunum. İyi ki 3İK var. KTÜ EMK olarak burada ve bu etkinliğe katılmaktan çok memnunum. Daha güzel ve etkili etkinliklerde görüşmek dileğiyle. \nİYİ Kİ 3İK VAR!",
            Name: "KTÜEMK - Ramazan Enes Kurşun",
            Logo: Karadeniz
        },{   
            Text: "Beklentimin çok üzerinde bir kamp deneyimi oldu. Hem şirketlerden hem de kulüplerden çok güzel insanlar tanıdım. İyi ki 3İK var!",
            Name: "EİK - Erdem Eren Çoşkun",
            Logo: SabanciEkonomi
        },{   
            Text: "Buraya gelmeden önce de içimde iyi geleceğine dair bir his vardı ve kamp beklediğimden de iyi geçti. Farkında olmadığım bir sürü fırsatı fark etmemi sağladı ve çok çok tatlı, kaliteli insanlarla tanıştım, aşırı eğlendim. İlk günden itibaren sıfır sorunla böyle bir organizasyonu yönetebilmek, organize edebilmek çok büyük bir başarı ve bu sebeple 3İK ailesini de tebrik etmek istiyorum. İyi ki gelmişim, iyi ki 3İK var!",
            Name: "SEMT - Pınar Örgün",
            Logo: SabanciEndustri
        },{   
            Text: "Sizinle dopdolu 4 günlük mükemmel, fazlasıyla verimli bir etkileşim geçirmekten gurur ve sevinç duyuyorum. Bana ve kulübüme bu şansı tanıdığınız için çok teşekkür ediyorum. Daha nice güzel etkinliklerde buluşmak dileğiyle.",
            Name: "EMK - Tuğba Şahin",
            Logo: Sakarya
        }
    ]

    return(
        <div className = "whatTheySay">
            <h2>E&G Kamp İçin Neler Söylediler?</h2>
            <div className = "whatTheySayContainer">
                <img onClick = {scrollToPrevPage} src = {arrowLeft} alt = "previous"/>
                <div className = "whatTheySayScroller">
                    {   
                        texts.map((text,i)=>{
                            return(
                                <div key={i} className = "whatTheySayContainerInner">
                                    <div className = "whatTheySayContainerImageDiv noSelect">
                                        <img src = {text.Logo} alt="" />
                                    </div>
                                    <p className = {`cardName ${text.Perfetti ? "cardNamePerfetti" : ""}`}>{text.Name}</p>
                                    <p className = "cardText">{
                                        window.innerWidth > 640 
                                            ?text.Text
                                            :text.Text.length > 210
                                                ? more
                                                    ? text.Text
                                                    : <>{text.Text.slice(0, 210)}<span onClick = {readMoreClickHandler}><br/><br/>Devamını Oku...</span></>
                                                : text.Text
                                    }</p>
                                </div>
                            )
                        })
                    }
                </div>
                <img onClick = {scrollToNextPage} src = {arrowRight} alt = "next"/>
            </div>
        </div>
    )
}

export default WhatTheySay;