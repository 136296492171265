import React from 'react';
import "./WhyExplainer.css";

const WhyExplainer = () => {    //ADD SPANS TO MAKE BOLD
    return (
        <div className = "whyExplainer">
            <h2>Neden Etkileşim&Gelişim Kampı?</h2>
            <p className = "whyExplainerBecause">Çünkü...</p>
            <div className = "whyExplainerItemsContainer">
                <p>Sektörün öncüsü şirketler ile 90 iş&kariyer kulübü arasında network fırsatı</p>
                <p>Mevcut şirket kültürünü doğrudan hedef kitleye yansıtma olanağı</p>
                <p>İşveren markası stratejilerini hedef üniversitelerin en etkin kulüpleri ile paylaşma şansı</p>
                <p>Hem kulüpler hem de şirketler için yıllık etkinlik stratejisi planlama imkanı</p>
                <p>4 gün süren dolu dolu bir deneyim</p>
                <p>Eğlenceli aktiviteler ve çok daha fazlası</p>
            </div>
            <p>Etkileşim&Gelişim Kampı’nda bizleri bekliyor!</p>
        </div>
    )
}

export default WhyExplainer;